<template>
    <b-table
            ref="refUserListTable"
            :items="items"
            :small="true"
            responsive
            :fields="columns"
            primary-key="id"
            show-empty
            empty-text="اطلاعاتی یافت نشد"
            class="text-nowrap"
            style="min-height : 235px"
    >
        <template #head(constant_fee)="data">
            <feather-icon icon="AlertCircleIcon"
                          size="20"
                          v-tooltip="'کارمزد کلی هر ارز برابر کارمزد صرافی (بایننس) و کارمزد ثابت برای هر ارز می باشد. با محاسبه مقادیر قیمت خرید و فروش درج شده در سایت بدست می آید.'"
            />
            {{ data.label }}
        </template>

        <template #cell(code)="{item}">
            <b-media>
                <template #aside>
<!--                    <cryptoicon v-if="item.code !== 'TOMAN'" :symbol="item.code" size="34"/>-->
                    <img height="34px" width="34px" :src="item.logo" alt="">
                </template>
                <b-link
                        class="font-weight-bold d-block text-nowrap"
                >
                </b-link>
                    <table-field-base :item="item"
                                      key-name="label"
                                      tooltip="اصلاح مقدار"
                                      :address="'/coins/' + item.id"
                    />
                <small class="text-muted">{{ item.code }}</small>
            </b-media>
        </template>

        <template #cell(status)="{item}">
            <!--            {{ $statusLabel[value] }}-->
            <table-field-base :item="item"
                              key-name="status"
                              tooltip="اصلاح مقدار"
                              :address="'/coins/' + item.id"
                              :options="statusItems"
            />
        </template>

        <template #cell(constant_fee)="{item}">
            <!--            {{ $statusLabel[value] }}-->
            <table-field-base v-if="item.code !== 'TOMAN'"
                              :item="item"
                              key-name="constant_fee"
                              tooltip="اصلاح مقدار"
                              :address="'/coins/' + item.id"
            />
        </template>

        <template #head(amount)="{field}">
                            <span class="d-block">
                                {{ field.label }}
                            </span>
        </template>

        <template #cell(amount)="{item}">
            {{ item.amount }}
        </template>


        <template #cell(actions)="row">
            <b-button size="sm" @click="row.toggleDetails" v-if="!!row.item.networks.length" variant="primary">
                {{ row.detailsShowing ? 'عدم' : '' }}
                نمایش شبکه
            </b-button>
        </template>

        <template #row-details="row">
            <b-card>
                <b-card v-for="(item, index) in row.item.networks" :key="index"
                        border-variant="primary"
                        :title="'نام شبکه' + item.name" :sub-title="'نوع شبکه' + item.network"
                >
                    <b-card-text>
                        <b-row no-gutters>
                            <b-col class="mb-1" md="6">کارمزد برداشت: {{ item.withdraw_fee }}</b-col>
                            <b-col class="mb-1" md="6">حداقل برداشت: {{ item.withdraw_min }}</b-col>
                            <b-col class="mb-1" md="6">حداکثر برداشت: {{ item.withdraw_max }}</b-col>
                            <b-col class="mb-1" md="6">وضعیت برداشت: {{ statusLabel(item.withdraw_status) }}</b-col>
                            <b-col class="mb-1" md="6">وضعیت واریز: {{ statusLabel(item.deposit_status) }}</b-col>
                            <b-col class="mb-1" md="12">
                                <table-field-base
                                        :item="item"
                                        :label="'آدرس کیف پول'"
                                        key-name="address"
                                        tooltip="اصلاح مقدار"
                                        :address="'/networks/'+ item.id + '/address'"
                                        method="post"
                                />
                            </b-col>
                            <b-col class="mb-1" md="12">
                                <table-field-base
                                        label="تگ"
                                        :item="item"
                                        key-name="memo"
                                        tooltip="اصلاح مقدار"
                                        :address="'/networks/'+ item.id + '/address'"
                                        method="post"
                                />

                            </b-col>
                            <b-col class="mb-1" md="12">
                                <table-field-base
                                        label="وضعیت"
                                        :item="item"
                                        key-name="status"
                                        tooltip="اصلاح مقدار"
                                        :address="'/networks/'+ item.id"
                                        method='patch'
                                        :options="statusItems"
                                />
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
            </b-card>
        </template>

    </b-table>
</template>

<script>
import {
    // BAvatar,
    BLink,
    BMedia,
    BTable,
    BButton,
    BCard,
    BRow,
    BCol
} from "bootstrap-vue";
import TableFieldBase from "@/Components/TableFieldBase";
// import CryptoCurrencyField from "@/views/CryptoCurrency/CryptoCurrencyTableField";

export default {
    name: "CryptoCurrencyTable",
    props: ['items'],
    components: {
        // CryptoCurrencyField,
        BTable,
        BMedia,
        // BAvatar,
        BCol,
        BRow,
        BLink,
        BButton,
        BCard,
        TableFieldBase
    },
    data() {
        return {
            dir: false,
            loading: false,
            columns: [
                {
                    label: 'رمز ارز',
                    key: 'code',
                    sortable: false,
                },
                {
                    label: 'وضعیت',
                    key: 'status',
                    sortable: false,
                },
                {
                    label: 'دارایی',
                    key: 'amount',
                    sortable: false,
                },
                {
                    label: 'کارمزد ثابت',
                    key: 'constant_fee',
                    sortable: false,
                },
                {
                    label: '',
                    key: 'actions'
                }
            ],
            edit: false,
            type: false,
            statusItems: [{text: 'فعال', value: 'ACTIVATED'}, {text: 'غیرفعال', value: 'DISABLED'}]
        }
    },
    computed: {
        statusLabel() {
            return i => i === 'ACTIVATED' ? 'فعال' : 'غیرفعال'
        }
    },
    methods: {
        // dataEntered(coin, e) {
        //     console.log(coin, e)
        //     this.edit = false
        //     // this.data[coin] = this.$S2N(this.$toLocal(e)) || 0
        //     // this.$forceUpdate()
        // },
    }
}
</script>

<style scoped>

</style>
