<template>
    <span class="w-100 text-right d-block cursor-pointer" v-if="!edit" style="text-align: right !important;"
          @click="change" v-tooltip="tooltip">
        <span v-if="label">
            {{ label }}:
        </span>
        {{ options ? getSelectedText(item[keyName]) : item[keyName] }}
        <b-badge pill variant="info">ویرایش</b-badge>
    </span>
    <b-overlay rounded :show="loading" v-else>
        <b-form @submit.prevent="submit(item)" @reset.prevent="edit=false" class="d-flex align-items-center">
            <label v-if="label"> {{ label }}: </label>
            <b-form-input v-if="!options" dir="ltr" class="py-0 px-50" style="min-width: 50px; width: auto" @input="updateData" :value="item[keyName]"/>
            <b-form-select v-else dir="ltr" class="py-0 px-50" @change="updateData" :value="item[keyName]"
                           :options="options"></b-form-select>
            <b-button v-tooltip="'ثبت'" class="btn-icon rounded-circle ml-50" size="sm"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" type="submit">
                <feather-icon icon="CheckIcon"/>
            </b-button>
            <b-button v-tooltip="'لغو'" class="btn-icon rounded-circle ml-50" size="sm" @click="reset"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-secondary" type="reset">
                <feather-icon icon="XIcon"/>
            </b-button>
        </b-form>
    </b-overlay>
</template>

<script>
import {BButton, BFormInput, BForm, BOverlay, BFormSelect, BBadge} from "bootstrap-vue";

export default {
    name: "TableFieldBase",
    props: ['item', 'keyName', 'tooltip', 'options', 'address', 'label', 'method'],
    components: {
        BButton,
        BFormInput,
        BForm,
        BOverlay,
        BFormSelect,
        BBadge
    },
    data() {
        return {
            edit: false,
            loading: false,
            lastValue: ''
        }
    },
    methods: {
        async submit(e) {
            console.log(e)
            this.loading = true

            let res = ''
            if (this.method === 'post') {
                res = await this.$axios.post(this.address, this.item).catch(() => {
                    this.reset()
                })
            } else if(this.method === 'patch'){
                res = await this.$axios.patch(this.address, this.item).catch(() => {
                    this.reset()
                })
            } else {
                res = await this.$axios.put(this.address, this.item).catch(() => {
                    this.reset()
                })
            }
            this.loading = false
            if (res) {
                const text = 'درخواست با موفقیت انجام شد.'
                this.$error('', text, 'success')
                this.edit = false
            }
        },
        change() {
            this.edit = true
            this.lastValue = this.item[this.keyName]
        },
        updateData(e) {
            let item = this.item
            item[this.keyName] = e
            this.$emit('input', item)
        },
        reset() {
            let item = this.item
            item[this.keyName] = this.lastValue
            this.$emit('input', item)
        }
    },
    computed: {
        getSelectedText() {
            return e => {
                let item = this.options.find(i => i.value === e)
                return item.text
            }
        }
    }
}
</script>

<style scoped>

</style>
